/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import LogoFooter from '../Assets/Imgs/LogoFooter.svg';
import LinkedinImage from '../Assets/Imgs/linkedin.svg';
import InstagramImg from '../Assets/Imgs/instagram.svg';
import LogoCoru from "../Assets/Imgs/coruCaas.svg";

import {
  Wrapper,
  LogoContainer,
  ColumnCoru,
  ColumnRelatedContent,
  ColumnFaq,
  TitleColumn,
  ListItems,
  Logo,
  Linkedin,
  Instagram,
  SocialContainer,
  ItemText,
} from './styles.jsx';
import { FooterVerticalsA } from '../../LpHomeCoru/Assets/styles/Footer.styled';

const Footer = ({
  i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, i11, i12, i13, selectLang
}) => {
  const [urlcontactanos, seturlcontactanos] = useState(`https://api.whatsapp.com/send?phone=+525613953649&text=Cómo puedo ponerme en contacto con ustedes?`);
  const [urlnosotros, seturlnosotros] = useState(`https://api.whatsapp.com/send?phone=+525613953649&text=¡Hola!, ¿Qué es Coru?`);
  const [urlsoporte, seturlsoporte] = useState(`https://api.whatsapp.com/send?phone=+525613953649&text=¿Cómo puedo obtener ayuda para mi servicio?`);

  useEffect(() => {
    switch (selectLang) {
      case 1:
        seturlcontactanos(`https://api.whatsapp.com/send?phone=+525613953649&text=¿Cómo puedo ponerme en contacto con ustedes?`);
        seturlnosotros(`https://api.whatsapp.com/send?phone=+525613953649&text=¡Hola!, ¿Qué es Coru?`);
        seturlsoporte(`https://api.whatsapp.com/send?phone=+525613953649&text=¿Cómo puedo obtener ayuda para mi servicio? Tengo una duda o problema, ¿en donde puedo contactarlos?`);
        break;
      case 2:
        seturlcontactanos(`https://api.whatsapp.com/send?phone=+525613953649&text=How can I get in touch with you?`);
        seturlnosotros(`https://api.whatsapp.com/send?phone=+525613953649&text=Hello! What is Coru?`);
        seturlsoporte(`https://api.whatsapp.com/send?phone=+525613953649&text=How can I get assistance for my service? If I have a question or problem, where can I contact you?`);
        break;
      case 3:
        seturlcontactanos(`https://api.whatsapp.com/send?phone=+5511997124616&text=Como posso entrar em contato com vocês?`);
        seturlnosotros(`https://api.whatsapp.com/send?phone=+5511997124616&text=Olá! O que é o Coru?`);
        seturlsoporte(`https://api.whatsapp.com/send?phone=+5511997124616&text=Como posso obter ajuda com o meu serviço? Se eu tiver uma dúvida ou problema, onde posso entrar em contato com vocês?`);
        break;
      default: break;
    }
  }, [selectLang]);
  return (
        <Wrapper>
            <LogoContainer>
                <Logo src={LogoCoru} alt='' />
                <SocialContainer>
                    <Linkedin src={LinkedinImage} alt='' onClick={() => window.open('https://www.linkedin.com/company/coru-com')}/>
                </SocialContainer>
            </LogoContainer>
            <ColumnCoru>
                <TitleColumn id="Holñaaaaa">{i1}</TitleColumn>
                <ListItems>
                    <ItemText target="_blanck" href={urlnosotros}>{i2}</ItemText>
                    <ItemText target="_blanck" href={urlcontactanos}>
                        {i3}
                    </ItemText>
                    <ItemText target="_blanck" href={urlsoporte}>
                        {i4}
                    </ItemText>
                </ListItems>
            </ColumnCoru>
        </Wrapper>
  );
};

export default Footer;
