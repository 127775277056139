import styled from "styled-components";

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: minmax(24px, 450px) auto 1fr auto minmax(24px, 450px);
    grid-template-rows: 60px auto 60px;
    height: auto;
    width: 100%;
    background-color: #FEFEFE;
    border-top-style: dashed;
    border-width: 1px;
    border-color: #F64282;
    @media screen and (max-width: 1440px) {
        grid-template-columns: minmax(24px, 150px) auto 1fr auto minmax(24px, 150px);
    }
    @media (max-width: 834px) {
        grid-template-columns: 16px 1fr 16px;
        grid-template-rows: 60px auto 40px auto 60px ;
    }
`;

export const LogoContainer = styled.div`
    height: 100%;
    max-width: 120px;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-row-start: 2;
    @media (max-width: 510px) {
        grid-column-start: 2;
        grid-row-start: 2;
        max-width: unset;
        min-width: unset;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 16px;
    }
`;

export const ColumnCoru = styled.div`
    grid-column-start: 4;
    grid-row-start: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 510px) {
        grid-column-start: 2;
        grid-row-start: 4;
    }
`;

export const ColumnRelatedContent = styled.div`
    grid-column-start: 6;
    grid-row-start: 2;
    height: 100%;
    /* max-width: 288px;
    min-width: 241px; */
    display: flex;
    flex-direction: column;
    @media (max-width: 510px) {
        grid-column-start: 2;
        grid-row-start: 6;
    }
`;

export const ColumnFaq = styled.div`
    grid-column-start: 8;
    grid-row-start: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 510px) {
        grid-column-start: 2;
        grid-row-start: 8;
    }
`;

export const TitleColumn = styled.h3`
    font-size: 16px;
    color: #24193F;
    font-weight: 700;
    line-height: 29px;
    align-self: stretch;
    flex-grow: 0;
    margin: 0;
    @media (max-width: 834px) {
        font-weight: 400;
        font-size: 16px;
    }
`;

export const ListItems = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Logo = styled.img`
    max-width: 120px;
    min-width: 100px;
    @media (max-width: 375px) {
        font-size: 20px;
        max-width: unset;
        min-width: unset;
        height: 38.35px;
        width: 80px ;
    }
`;

export const SocialContainer = styled.div`
    margin-top: 28px;
    display: flex;
    gap: 12px;
    @media (max-width: 834px) {
        gap: 6.12px;
        margin: 0;
        align-items: center;
    }
`;

export const Linkedin = styled.img`
    width: 40px;
    @media (max-width: 834px) {
        width: 24px;
        height: 24px;
    }
    `;

export const Instagram = styled.img`
    width: 40px;
    @media (max-width: 834px) {
        width: 24;
        height: 24px;
    }
`;

export const ItemText = styled.a`
    color: #595959;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    margin: 0;
    @media (max-width: 834px) {
        font-size: 14px;
        font-weight: 400;
    }
    &:hover{
        color: #595959;
        text-decoration:none;
    }
`;
