/* eslint-disable react/prop-types */
import React from 'react';
import Go from '../../../components/CaasHomeNew/Assets/Imgs/partners/2Go.svg';
import Arcelor from '../../../components/CaasHomeNew/Assets/Imgs/partners/ArcelorMittal.svg';
import Baz from '../../../components/CaasHomeNew/Assets/Imgs/partners/Baz.svg';
import Cartao from '../../../components/CaasHomeNew/Assets/Imgs/partners/Cartao.svg';
import CMI from '../../../components/CaasHomeNew/Assets/Imgs/partners/CMI.svg';
import Furukawa from '../../../components/CaasHomeNew/Assets/Imgs/partners/furukawa.svg';
import Fs from '../../../components/CaasHomeNew/Assets/Imgs/partners/Fs.svg';
import GrupoTrigo from '../../../components/CaasHomeNew/Assets/Imgs/partners/GrupoTrigo.svg';
import Keune from '../../../components/CaasHomeNew/Assets/Imgs/partners/Keune.svg';
import Loft from '../../../components/CaasHomeNew/Assets/Imgs/partners/Loft.svg';
import MercadoBitcoin from '../../../components/CaasHomeNew/Assets/Imgs/partners/MercadoBitcoin.svg';
import Pagbank from '../../../components/CaasHomeNew/Assets/Imgs/partners/Pagbank.svg';
import Rentokil from '../../../components/CaasHomeNew/Assets/Imgs/partners/Rentokil.svg';
import Wantel from '../../../components/CaasHomeNew/Assets/Imgs/partners/Wantel.svg';
import azteca from '../../../components/CaasHomeNew/Assets/Imgs/partners/azteca.png';
import {
  Title, CarruselContainer, Wrapper, Content, RowContentImg,
} from './styles.jsx';

const Partners = ({ info }) => (
    <Wrapper>
        <Title>{info}</Title>
        <CarruselContainer>
            <Content>
                <RowContentImg>
                    <img src={Go} alt="2Go" />
                    <img src={azteca} alt="azteca" height={40} />
                    <img src={Arcelor} alt="ArcelorMittal" />
                    <img src={Baz} alt="Baz" />
                    <img src={CMI} alt="CMI" />
                    <img src={Cartao} alt="Cartao" />
                    <img src={Fs} alt="Fs" />
                    <img src={Furukawa} alt="Furukawa" />
                    <img src={GrupoTrigo} alt="GrupoTrigo" />
                    <img src={Keune} alt="Keune" />
                    <img src={Loft} alt="Loft" />
                    <img src={MercadoBitcoin} alt="MercadoBitcoin" />
                    <img src={Pagbank} alt="Pagbank" />
                    <img src={Rentokil} alt="Rentokil" />
                    <img src={Wantel} alt="Wantel" />
                </RowContentImg>
            </Content>
        </CarruselContainer>
    </Wrapper>
);

export default Partners;
