/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom'
import { GET_TRANSLATE_INFO } from "../../queries";
import { INSERT_ROUTINE, DYNAMIC_UPDATE } from '../../mutations';
import A2 from "./Assets/Imgs/Aquisition.svg";
import P from "./Assets/Imgs/Products.svg";
import Whatsapp from "../Training/Whatsapp";
import Coru from "./Assets/Imgs/ICONCORU.svg";
import Stories from "./Assets/Imgs/FEEDBACK.svg";
import Footer from "./Footer";
import LetsStarted from "./LetsStarted";
import WhyCoru from "./WhyCoru";
import Partners from "./Partners";
import {
  GenContainer,
  ContainerPage2,
  Contact,
  ContactNumber,
  Menu,
  MenuOptions,
  ContainerGeneral,
} from "./Assets/Styles/CaasNew.styles.js";
import MyCarousel from "./CustomCarousel/CustomCarousel";
import ClickButton from "../../Helpers/HookHelpers";
import Navbar from "./Navbar";
import EmpowerBusiness from "./EmpowerBusiness";
import CoruService from "./CoruService";
import CapacidadesEsp from "./Capacidades/index.js";
import GlobalScale from "./GlobalScale";
import LanguageMenu from "./LanguageMenu";

const CaasNew = () => {
  const currentUrl = window.location.href;
  const history = useHistory();
  const [matches1, setMatches1] = useState(false);
  const [matches2, setMatches2] = useState(false);
  const [isMobil, setIsMobil] = useState();
  const [showDemo, setShowDemo] = useState(false);
  const [menu, setMenu] = useState(false);
  const [languageMenu, setLanguageMenu] = useState(false);
  const [selectLang, setSelectLang] = useState(2);
  const { actions } = ClickButton();
  const [infoTranslate, setInfoTranslate] = useState(null);
  const urlh = window.location.href;
  const [url, seturl] = useState(`https://api.whatsapp.com/send?phone=+525613953649&text=Me gustaría aprender cómo la ADI de Coru puede mejorar las interacciones entre la empresa y los usuarios a través de asistencia personalizada.`);
  const [urlheader, seturlheader] = useState('https://adi.coru.com/register?utm_source=Coru&utm_medium=lpcaas-es&utm_campaign=header&utm_id=23855410042890080 ');
  const [urlhero, seturlhero] = useState("https://adi.coru.com/register?utm_source=Coru&utm_medium=lpcaas-es&utm_campaign=hero&utm_id=23855410042890080");
  const [urlWhyCoru, seturlWhyCoru] = useState("https://share.hsforms.com/1cTMnENQVTheYZ9gssQlbNw4y8hb");
  const ln = navigator.language || navigator.userLanguage;
  const [title, settitle] = useState("Customer experience y digitalización para empresas | CORU");
  const [meta, setmeta] = useState("Somos una plataforma de customer experience con tecnologías como inteligencia artificial, onboarding digital y más soluciones para empresas.");
  const [languageMeta, setlanguageMeta] = useState("es-MX");
  const [urlbtnheader, seturlbtnheader] = useState("https://docs.google.com/presentation/d/1PPH4cuHeFctZruSfHs7Jk19V5Sa5_wfXRqIKp-Ft7I4/edit#slide=id.g2627179b470_0_27");

  function analyzeCookie() {
    let galletita = document.cookie;
    let source = '';
    let medium = '';
    let campaign = '';
    let variableEstado = '';
    let date = '';
    let content = '';
    let term = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=')) {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=')) {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=')) {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('term=')) {
        term = elementos[elem].replace('term=', '');
      } else if (elementos[elem].includes('content=')) {
        content = elementos[elem].replace('content=', '');
      } else if (elementos[elem].includes('date=')) {
        date = elementos[elem].replace('date=', '');
      }
    }

    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medium);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
    ReactGA.ga((tracker) => {
      const idGa = tracker.get('clientId');
      sessionStorage.setItem('idGa', idGa);
    });
  }

  const [getInfoTranslate] = useLazyQuery(GET_TRANSLATE_INFO, {
    onCompleted({ getTranslateInfo }) {
      const { error, message, response } = getTranslateInfo;
      console.log(JSON.parse(response));
      setInfoTranslate(JSON.parse(response));
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });

  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');

        if (insertRoutine.message === 'usuario_existente') {
          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            /*  if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            } */
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const saveMongoUtms = () => {
    actions({
      action: "ClickButtonLPCaaS",
      variables: {
        source: sessionStorage.getItem('source'),
        medium: sessionStorage.getItem('medium'),
        campaign: sessionStorage.getItem('campaign'),
        IdCampaign: sessionStorage.getItem('affiliateClickId'),
        IdGa: sessionStorage.getItem('idGa')
      },
      collection: "ClickButtonLPCaaS",
    });
  };

  useEffect(() => {
    if (currentUrl.includes('?en')) {
      history.replace('/caas/en/');
    } else if (currentUrl.includes('?es')) {
      history.replace('/caas/es/');
    } else if (currentUrl.includes('?pt')) {
      history.replace('/caas/pt/');
    }
  }, [])

  useEffect(() => {
    // saveMongoUtms();
    // analyzeCookie();
    getInfoTranslate({
      variables: {
        language: selectLang,
      },
    });
    switch (selectLang) {
      case 1:
        seturl("https://share.hsforms.com/1cTMnENQVTheYZ9gssQlbNw4y8hb");
        seturlheader('https://api.whatsapp.com/send?phone=+525613953649&text=%C2%BFC%C3%B3mo%20puedo%20ponerme%20en%20contacto%20con%20ustedes?');
        seturlhero("https://share.hsforms.com/1cTMnENQVTheYZ9gssQlbNw4y8hb");
        seturlWhyCoru("https://share.hsforms.com/1cTMnENQVTheYZ9gssQlbNw4y8hb");
        settitle('IA que habla el idioma de tu negocio');
        setmeta("Somos una plataforma de customer experience con tecnologías como inteligencia artificial, onboarding digital y más soluciones para empresas.");
        setlanguageMeta("es-MX");
        seturlbtnheader("https://share.hsforms.com/1cTMnENQVTheYZ9gssQlbNw4y8hb");
        break;
      case 2:
        seturl("https://share.hsforms.com/1cTMnENQVTheYZ9gssQlbNw4y8hb");
        seturlheader('https://api.whatsapp.com/send?phone=+525613953649&text=How%20can%20I%20get%20in%20touch%20with%20you?');
        seturlhero("https://share.hsforms.com/1cTMnENQVTheYZ9gssQlbNw4y8hb");
        seturlWhyCoru("https://share.hsforms.com/1cTMnENQVTheYZ9gssQlbNw4y8hb");
        settitle('AI fluent in your business');
        setmeta("We are a customer experience platform with technologies such as artificial intelligence, digital onboarding, and more solutions for companies.");
        seturlbtnheader("https://share.hsforms.com/1cTMnENQVTheYZ9gssQlbNw4y8hb");
        setlanguageMeta("en-US");
        break;
      case 3:
        seturl("https://meetings.hubspot.com/fpaccele?uuid=2ed3bf2e-6b06-44d5-a784-498834ef6f2b");
        seturlheader('https://api.whatsapp.com/send?phone=+5511997124616&text=Como%20posso%20entrar%20em%20contato%20com%20voc%C3%AAs?');
        seturlhero("https://meetings.hubspot.com/fpaccele?uuid=2ed3bf2e-6b06-44d5-a784-498834ef6f2b");
        seturlWhyCoru("https://meetings.hubspot.com/fpaccele?uuid=2ed3bf2e-6b06-44d5-a784-498834ef6f2b");
        settitle("IA que fala a língua do seu negócio");
        setmeta("Nós somos uma plataforma de experiência do cliente com tecnologias como inteligência artificial, onboarding digital e mais soluções para empresas.");
        seturlbtnheader("https://meetings.hubspot.com/fpaccele?uuid=2ed3bf2e-6b06-44d5-a784-498834ef6f2b");
        setlanguageMeta("pt-BR");
        break;
      default:
        seturl("https://meetings.hubspot.com/gustavo-moro/meeting-lp-mx");
        seturlheader('https://api.whatsapp.com/send?phone=+525613953649&text=%C2%BFC%C3%B3mo%20puedo%20ponerme%20en%20contacto%20con%20ustedes?');
        seturlhero("https://adi.coru.com/register?utm_source=Coru&utm_medium=lpcaas-es&utm_campaign=hero&utm_id=23855410042890080");
        seturlWhyCoru("https://meetings.hubspot.com/gustavo-moro/meeting-lp-mx");
        settitle('IA que habla el idioma de tu negocio');
        setmeta("Somos una plataforma de customer experience con tecnologías como inteligencia artificial, onboarding digital y más soluciones para empresas.");
        setlanguageMeta("es-MX");
        break;
    }
  }, [selectLang]);

  useEffect(() => {
    if (urlh.includes("/en/")) {
      setSelectLang(2);
    } else if (urlh.includes("/pt/") || urlh.includes(".com.br")) {
      setSelectLang(3);
    } else {
      setSelectLang(1);
    }
  }, [urlh]);

  useEffect(() => {
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    // const numQuestion = 0;
    // inputRutine.email = sessionStorage.getItem('Email1');
    inputRutine.cellphone = sessionStorage.getItem('CellPhone');
    inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
    inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
    inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
    inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
    // inputRutine.rutine[parseInt(numQuestion)] = rutine;
    insertRoutine({ variables: { input: inputRutine } });
  }, []);

  const setInfo = (section) => {
    for (let i = 0; i < infoTranslate.length; i++) {
      switch (section) {
        case infoTranslate[i].Section:
          return infoTranslate[i].Description;
        default:
          break;
      }
    }
    return undefined;
  };

  useEffect(() => {
    const mediaQuery1 = window.matchMedia("(min-width: 1280px)");
    setMatches1(mediaQuery1.matches);

    const handleChange1 = (event) => {
      setMatches1(event.matches);
    };

    mediaQuery1.addEventListener("change", handleChange1);

    const mediaQuery2 = window.matchMedia("(max-width: 834px)");
    setMatches2(mediaQuery2.matches);

    const handleChange2 = (event) => {
      setMatches2(event.matches);
    };

    mediaQuery2.addEventListener("change", handleChange2);

    const mediaQueryMobil = window.matchMedia("(max-width: 375px)");
    setIsMobil(mediaQueryMobil.matches);

    const isMediaQueryMobilListener = (event) => {
      setIsMobil(event.matches);
    };

    mediaQueryMobil.addEventListener("change", isMediaQueryMobilListener);
    return () => {
      mediaQuery1.removeEventListener("change", handleChange1);
      mediaQuery2.removeEventListener("change", handleChange2);
      mediaQueryMobil.removeEventListener("change", isMediaQueryMobilListener);
    };
  }, []);

  const handleClickCoru = (name) => {
    switch (name) {
      case "solutions":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_solutions_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "products":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_products_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "coru":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_coru_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "successstories":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_casosexito_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "traductor":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_traductor_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "getstarted":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_demo_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });

        break;
      case "BottonReadytoStartHero":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonDemo_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonLetsGetToWork":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_btndemoProductos_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonStartnow":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonStartnow`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonSeeallProducts":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonSeeallProducts`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const urlvalid = urlh.split("/");
    if( urlvalid.length <= 4 ){
      if (ln === 'pt' || ln === "pt-BR" || urlh.includes(".com.br")) {
        window.location.href = "/caas/pt/";
        setSelectLang(3);
      } else if (ln === 'en' || ln === "en-US") {
        window.location.href = "/caas/en/";
        setSelectLang(2);
      } else {
        setSelectLang(1);
        window.location.href = "/caas/es/";
      }
    }
    const num = urlvalid.length - 1;
    let finalurl = urlvalid[num];
    // console.log(ln, "*****************************");
    if (finalurl === "") {
      finalurl = urlvalid[num - 1];
    }
    if (finalurl === "caas") {
      if (ln === 'pt' || ln === "pt-BR" || urlh.includes(".com.br")) {
        window.location.href = "/caas/pt/";
        setSelectLang(3);
      } else if (ln === 'en' || ln === "en-US") {
        window.location.href = "/caas/en/";
        setSelectLang(2);
      } else {
        setSelectLang(1);
        window.location.href = "/caas/es/";
      }
    }
  }, []);

  if (infoTranslate === null) {
    return null;
  }
  return (
    <>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={meta} data-react-helmet="true" />
          <meta property="og:description" content={meta}/>
          <meta property="og:title" content={title} />
          <meta httpEquiv="content-language" content={languageMeta} />
          <meta name="robots" content="index, follow"/>
          <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"></meta>
        </Helmet>
      {infoTranslate ? (
      <>
        <GenContainer>
          {/* -------------navbar------------- */}
          <Navbar
          /* i1={setInfo("HeaderS")}
          i2={setInfo("HeaderP")}
          i3={setInfo("HeaderC")}
          i4={setInfo("HeaderSS")} */
          i5={setInfo("HeaderContact")}
          i6={setInfo("Header_Button")}
          matches1={matches1}
          matches2={matches2}
          languageMenu={languageMenu}
          setLanguageMenu={setLanguageMenu}
          url={urlheader}
          urlbtnheader={urlbtnheader}
          showDemo={showDemo}
          setShowDemo={setShowDemo}
          />
          {/* -------------seccion 1------------- */}
          <EmpowerBusiness
          i1={setInfo("Start_Title")}
          i2={setInfo("Start_TitleD")}
          i3={setInfo("Start_TitleC")}
          i6={setInfo("Start_TitleA")}
          i4={setInfo("Start_Subtitle")}
          i5={setInfo("Start_Button")}
          language={selectLang}
          url={urlhero}
          />
          {/* -------------seccion 2 Coru as a Service------------- */}
          <CoruService
            i1={setInfo("CoruService")}
            i2={setInfo("CoruService_Title")}
            i3={setInfo("CoruService_Subtitle")}
            i4={setInfo("Products")}
            i5={setInfo("Products_Title")}
            i6={setInfo("CoruService_Optiontitle1")}
            i7={setInfo("CoruService_Optiontitle2")}
            i8={setInfo("CoruService_Optiontitle3")}
            i9={setInfo("CoruService_Optiontext1")}
            i10={setInfo("CoruService_Optiontext2")}
            i11={setInfo("CoruService_Optiontext3")}
            i12={setInfo("Products_SubSubTitle")}
            i13={setInfo("HeaderP")}
            i14={setInfo("Products_TitleOption1")}
            i15={setInfo("Products_TitleOption2")}
            i16={setInfo("Products_TitleOption3")}
            i17={setInfo("Products_TitleOption4")}
            i18={setInfo("Products_TitleOption5")}
            i19={setInfo("Products_TitleOption6")}
            i20={setInfo("Products_Text1")}
            i21={setInfo("Products_Text2")}
            i22={setInfo("Products_Text3")}
            i23={setInfo("Products_Text4")}
            i24={setInfo("Products_Text5")}
            i25={setInfo("ProductosVerMasRosa_Text1")}
            i26={setInfo("ProductosVerMasRosa_Text2")}
            i27={setInfo("ProductosVerMasRosa_Text3")}
            i28={setInfo("ProductosVerMasRosa_Text4")}
            i29={setInfo("ProductosVerMas_Text1")}
            i30={setInfo("ProductosVerMas_Text2")}
            i31={setInfo("ProductosVerMas_Text3")}
            i32={setInfo("ProductosVerMas_Text4")}
            i33={setInfo("ProductosVerMasLista_Text1")}
            i34={setInfo("ProductosVerMasLista_Text2")}
            i35={setInfo("ProductosVerMasLista_Text3")}
            i36={setInfo("ProductosVerMasLista_Text4")}
            i37={setInfo("ProductosVerMasLista_Text5")}
            i38={setInfo("ProductosVerMasLista_Text6")}
            i39={setInfo("Products_Text6")}
            i40={setInfo("Productos_ButtonVerMasP")}
            i41={setInfo("Productos_ButtonVerMenos")}
            i42={setInfo("Productos_ButtonVerMas")}
          />

          <ContainerPage2
            position="relative"
            padding="60px 0px 0px 0px"
            mobilPadding="40px 16px"
            tabletPadding="40px 16px"
            style={{ backgroundColor: "#ffffff" }}
          >
            {/* -----seccion 3 nuestra escala global-------- */}
            <GlobalScale
            i1={setInfo("GlobalScale")}
            i2={setInfo("GlobalScale_Title")}
            i3={setInfo("GlobalScale_Subtitle")}
            i4={setInfo("GlobalScale_Subtitle1")}
            i5={setInfo("GlobalScale_Subtitle2")}
            i6={setInfo("GlobalScale2_Title")}
            i7={setInfo("GlobalScale2_Subtitle")}
            i8={setInfo("GlobalScale2_Subtitle1")}
            i9={setInfo("GlobalScale2_Subtitle2")}
            i10={setInfo("GlobalScale3_Title")}
            i11={setInfo("GlobalScale3_Subtitle")}
            i12={setInfo("GlobalScale3_Subtitle1")}
            i13={setInfo("GlobalScale3_Subtitle2")}
            selectLang={selectLang}
            />
            {/* ---------seccion 4 carrusel- Quiénes confían en nosotros-------- */}
            <Partners info={setInfo("Partners")} />
          </ContainerPage2>
           <WhyCoru
            i1={setInfo("WCoru")}
            i2={setInfo("WCoru_Title")}
            i3={setInfo("WCoru_Subtitle")}
            i4={setInfo("WCoru_Button")}
            i5={setInfo("WCoru2_Title")}
            i6={setInfo("WCoru2_Subtitle")}
            i7={setInfo("WCoru3_Title")}
            i8={setInfo("WCoru3_Subtitle")}
            i9={setInfo("WCoru4_Title")}
            i10={setInfo("WCoru4_Subtitle")}
            WCoru2_Description={setInfo("WCoru2_Description")}
            idc={setInfo}
            url={urlWhyCoru}
          />
          <LetsStarted
            i1={setInfo("Started_Title")}
            i2={setInfo("Started_Subtitle")}
            i3={setInfo("Started_Button")}
            i4={setInfo("Started_ButtonSecundary")}
            language={selectLang}
            url={url}
          />
          {/* {selectLang === 1 && (
            <GetCard />
          )} */}
          {menu && !matches1 ? (
            <Menu>
              <MenuOptions
                style={{ cursor: "pointer" }}
                onClick={() => handleClickCoru("solutions")}
              >
                <img alt="" src={A2} /> {setInfo("HeaderS")}
              </MenuOptions>
              <MenuOptions
                style={{ cursor: "pointer" }}
                onClick={() => handleClickCoru("products")}
              >
                <img alt="" src={P} />
                {setInfo("HeaderP")}
              </MenuOptions>
              <MenuOptions
                style={{ cursor: "pointer" }}
                onClick={() => handleClickCoru("coru")}
              >
                <img alt="" src={Coru} />
                {setInfo("HeaderC")}
              </MenuOptions>
              <MenuOptions
                style={{ cursor: "pointer" }}
                onClick={() => handleClickCoru("successstories")}
              >
                <img alt="" src={Stories} />
                {setInfo("HeaderSS")}
              </MenuOptions>
              <MenuOptions>
                <Contact style={{ cursor: "pointer" }}>
                  {setInfo("HeaderContact")}
                  <ContactNumber>+52 55 5011-9400</ContactNumber>
                </Contact>
              </MenuOptions>
            </Menu>
          ) : null}
          {languageMenu && <LanguageMenu /> }
          <Footer
            i1={setInfo("Footer_Title")}
            i2={setInfo("Footer_Subtitle")}
            i3={setInfo("Footer_Subtitle2")}
            i4={setInfo("Footer_Subtitle3")}
            i5={setInfo("Footer2_Title")}
            i6={setInfo("Footer2_Subtitle")}
            i7={setInfo("Footer2_Subtitle2")}
            i8={setInfo("Footer2_Subtitle3")}
            i9={setInfo("Footer2_Subtitle4")}
            i10={setInfo("Footer3_Title")}
            i11={setInfo("Footer3_Subtitle")}
            i12={setInfo("Footer3_Subtitle2")}
            i13={setInfo("Footer3_Subtitle3")}
            selectLang={selectLang}
          />
        </GenContainer>
        <Whatsapp url={window.location.pathname} id="Whatsapp" />
      </>
      ) : null}
    </>
  );
};

export default CaasNew;
